@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  src: local('Open Sans'), 
  url(./assets/fonts/Open_Sans/OpenSans-Regular.woff2) format('woff2'),
  url(./assets/fonts/Open_Sans/OpenSans-Regular.woff) format('woff'),
  url(./assets/fonts/Open_Sans/OpenSans-Regular.ttf) format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans'), 
  url(./assets/fonts/Open_Sans/OpenSans-Regular.woff2) format('woff2'),
  url(./assets/fonts/Open_Sans/OpenSans-Regular.woff) format('woff'),
  url(./assets/fonts/Open_Sans/OpenSans-Regular.ttf) format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans'), 
  url(./assets/fonts/Open_Sans/OpenSans-SemiBold.woff2) format('woff2'),
  url(./assets/fonts/Open_Sans/OpenSans-SemiBold.woff) format('woff'),
  url(./assets/fonts/Open_Sans/OpenSans-SemiBold.ttf) format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  src: local('Source Sans Pro'), 
  url(./assets/fonts/Source_Sans_Pro/SourceSansPro-Regular.woff2) format('woff2'),
  url(./assets/fonts/Source_Sans_Pro/SourceSansPro-Regular.woff) format('woff'),
  url(./assets/fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf) format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: local('Source Sans Pro'), 
  url(./assets/fonts/Source_Sans_Pro/SourceSansPro-SemiBold.woff2) format('woff2'),
  url(./assets/fonts/Source_Sans_Pro/SourceSansPro-SemiBold.woff) format('woff'),
  url(./assets/fonts/Source_Sans_Pro/SourceSansPro-SemiBold.ttf) format('truetype');
  font-display: swap;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Open Sans', 'Source Sans Pro',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
