.eb_btn_blue {
    border: 1px solid #3c44b1;
    border-radius: 16px;
    text-align: center;
    background-color: #fff;
    font-size: '14px';
    line-height: '20px';
    color: #0a15af;
    cursor: pointer;
    width: 100px;
    height: 35px;
  }
  
  .eb_btn_blue:focus {
    outline: 0 !important;
  }
  
  .eb_btn_blue:hover {
    background-color: #e5e5f3;
  }
  
  .eb_text_blue {
    color: #212ed9;
  }
  .eb_text_blue:link {
    color: #212ed9;
  }
  .eb_text_blue:hover {
    color: #2f3ad4;
  }
  .eb_text_blue:visited {
    color: #212ed9;
  }
  
  .eb_center {
    color: #444;
    text-align: center;
    width: 350px;
    height: 250px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  