.App {
  text-align: center;
}

.tox-toolbar__primary {
  background-color: #f0f3f8 !important;
  background-image: none !important;
  border-bottom: 1px solid #C7CCD8 !important;
}

.tox-toolbar--scrolling {
  background-color: #f0f3f8 !important;
  background-image: none !important;
  border-bottom: 1px solid #C7CCD8 !important;
  flex-wrap: wrap !important;
}